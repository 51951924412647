"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    busyDelay: 500,
    appName: 'RWD Blog',
    genericError: 'Something went wrong, please try again',
    apiBase: 'https://blogapi.rwd.group',
    apiRoutes: {
        LOGIN: '/login',
        LOGOUT: '/logout',
        FORGOT_PASSWORD: '/forgottenPassword',
        RESET_LINKS: '/resetLinks',
        RESET_PASSWORD: '/resetPassword',
        USER: '/users/self',
        CHANGE_PASSWORD: '/changePassword',
        WEBSITES: '/websites',
        BLOG_POSTS: '/blogPosts',
        BLOG_IMAGES: '/images',
        BLOG_TAGS: '/blogTags'
    },
    storageKeys: {
        USER: 'RWD_BLOG_CMS_USER',
        PREFS: 'RWD_BLOG_CMS_PREFS'
    },
    userRoles: {
        USER: 'ROLE_USER',
        ADMIN: 'ROLE_ADMIN',
        SUPER_ADMIN: 'ROLE_SUPER_ADMIN'
    }
};
