"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlogPostStore = void 0;
var Store_1 = require("./Store");
var BlogPostStore = /** @class */ (function (_super) {
    __extends(BlogPostStore, _super);
    function BlogPostStore(data) {
        var _this = _super.call(this, data) || this;
        _this.setData = function (data) {
            _this.data = data;
        };
        return _this;
    }
    return BlogPostStore;
}(Store_1.Store));
exports.BlogPostStore = BlogPostStore;
var initial = [
// {
//   id: 1,
//   title: 'Test blog post',
//   date: dayjs().format(),
//   url: 'test-blog-post',
//   text: '<p>This is a test blog post</p>',
//   live: true,
//   featured: false
// }
];
var blogPostStore = new BlogPostStore(initial);
exports.default = blogPostStore;
