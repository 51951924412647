"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var BlogEditorReadTimeInput = function (_a) {
    var value = _a.value, onKeyUp = _a.onKeyUp;
    var _b = react_1.useState(false), focus = _b[0], setFocus = _b[1];
    return (react_1.default.createElement("div", { className: 'blog-editor__readtime-input', "data-focus": focus },
        react_1.default.createElement("label", null, "Read Time: "),
        react_1.default.createElement("input", { type: 'text', placeholder: 'Add a read time', name: 'readTime', onKeyUp: onKeyUp, defaultValue: value, autoFocus: false, onFocus: function () { return setFocus(true); }, onBlur: function () { return setFocus(false); } })));
};
exports.default = BlogEditorReadTimeInput;
